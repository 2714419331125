<template>
	<div 
    class="eye-container"
    v-if="hasValue" 
  >
		<div class="container-header">
			<h4>Curva tensional</h4>
			<div 
        class="chevron" 
       @click="handleOpen()" v-bind:class="{ rotateUp: !open, rotateDown: open }"
      >
				<ChevronUp />
			</div>
		</div>
    <section v-if="this.open" v-bind:class="{ openTable: open, closeTable: !open }">
      <b-col cols="12" class="pr-0 pl-0">
        <div class="form-group mb-0" v-if="form?.aparelho">
          Aparelho de medição: {{ form?.aparelho }}
        </div>
      </b-col>
      <b-row>
        <b-col cols="9" class="pr-0 pl-2 pt-3 pb-3">
          <table>
            <thead>
              <tr>
                <th>
                  <Clock class="icon" />
                </th>
                <th>
                  <EyeFill class="icon mr-2"/> D
                </th>
                <th>
                  <EyeFill class="icon mr-2" /> E
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) in form.value" :key="index">
                <b-input
                  :value="value.hora"
                  v-mask="'##:##'"
                  readonly
                ></b-input>
                <b-input
                  class="no-border"
                  type="number"
                  :value="value.direito"
                  readonly
                ></b-input>
                <b-input
                  :value="value.esquerdo"
                  type="number"
                  readonly
                ></b-input>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col class="chart-container ml-3 mt-3">
          <b-row>
            <div :id="`curva-tensional-${id}`">
              <Graph 
                class="ml-3 mt-2 cursor-pointer" 
              />
            </div>
            <b-popover :target="`curva-tensional-${id}`" triggers="hover" placement="top" >
              <b-row class="ml-3 mt-3">
                <h5>Curva tensional</h5>
              </b-row>
              <b-row class="ml-2">
                <b-col v-if="form?.aparelho">
                  <p>Aparelho de medição: {{ form?.aparelho }}</p>
                </b-col>
                <b-col >
                    <StraightChart /> Olho direito
                </b-col>
                <b-col>
                  <DashedChart /> Olho esquerdo
                </b-col>
              </b-row>
              <v-line-chart 
                :key="form.value?.length"
                :data="getChart()"
                :options="chartOptions"
              />
            </b-popover>
          </b-row>
        </b-col>
      </b-row>
      <b-col v-if="form?.laudo?.length" class="pr-0 pl-0" >
        <strong>Laudo:</strong> {{ form?.laudo }}
      </b-col>
    </section>
	</div>
</template>

<script>

import EyeFill from '@/assets/icons/eye-fill.svg'
import ChevronUp from '@/assets/icons/chevron-up.svg'
import Clock from '@/assets/icons/clock.svg'
import Graph from '@/assets/icons/graph.svg'
import LineChart from '@/components/LineChart'
import DashedChart from '@/assets/icons/dashed-chart-line.svg'
import StraightChart from '@/assets/icons/straight-chart-line.svg'

export default {
	components: {
		EyeFill,
		ChevronUp,
    Clock,
    Graph, StraightChart, DashedChart,
    'v-line-chart': LineChart,
	},

	props: {
    id: String,
		form: Object,
	},

  computed: {
    hasValue() {
      Object.values(this.form.value).forEach(value => {
        Object.values(value).forEach(val => {
          if(val) {
            this.value2 = true
            return this.value2
          }   
        });
      });
      return this.value2
    }
  },

	data() {
		return {
      value2: false,
			open: true,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: '#D9DFF2'
              },
              ticks: {
                stepSize: 1,
                fontSize: 10,
                fontColor: '#8696AC'
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                borderDash: [10, 2],
                color: '#D9DFF2'
              },
              ticks: {
                fontSize: 10,
                fontColor: '#8696AC'
              }
            }
          ]
        },
        tooltips: {
          mode: 'label',
          bodyAlign: 'center'
        }
      },
		}
	},

	methods: {
		handleOpen() {
			this.open = !this.open
		},
    getChart () {
      const horas = this.form?.value?.map(el => el.hora)
      const esquerdo = this.form?.value?.map(el => el.esquerdo)
      const direito = this.form?.value?.map(el => el.direito)

      return  {
        labels: [...new Set(horas.filter(n => n))],
        datasets: [
          {
            label: 'Olho direito',
            borderColor: 'rgba(48, 91, 242, 1)',
            backgroundColor: 'rgba(48, 91, 242, 0.1)',
            pointBackgroundColor: 'rgba(48, 91, 242, 1)',
            pointRadius: 4,
            pointHoverBackgroundColor: '#FFFFFF',
            pointHoverBorderWidth: 2,
            lineTension: 0.3,
            data: direito.filter(n => n)
          },
          {
            label: 'Olho esquerdo',
            borderColor: '#FF6B00',
            backgroundColor: '#FF6B00',
            pointBackgroundColor: '#FF6B00',
            pointRadius: 4,
            pointHoverBackgroundColor: '#FFFFFF',
            pointHoverBorderWidth: 2,
            lineTension: 0.3,
            data: esquerdo.filter(n => n)
          }
        ]
      }
    },
	},
}
</script>

<style lang="scss" scoped>
.margins {
	margin: 20px 0;
}

.eye-container {
    display: flex;
    flex-direction: column;

    .container-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 30px;

      h3 {
        font-size: 18px;
        color: var(--dark-blue);
        font-weight: 600;
      }

      .chevron {
        width: 25px;
        height: 25px;

        >svg {
          cursor: pointer;
          stroke: var(--neutral-600);
          transition: .5s;
        }
      }

      .rotateUp {
        animation: rotateUp .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
      }

      .rotateDown {
        animation: rotateDown .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
      }

      @keyframes rotateUp {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(180deg);
        }
      }

      @keyframes rotateDown {
        0% {
          transform: rotate(180deg);
        }

        100% {
          transform: rotate(0deg);
        }
      }

    }


    h4 {
      color: var(--dark-blue);
      font-weight: 600;
      font-size: 18px;
    }
    .icon {
      width: 18px;
      height: 18px;
    }

  table {
    width: 100%;
    thead {
      tr {
        display: flex;
        flex-direction: row;
      }
      tr th:nth-child(-n + 3) {
        width: 100%;
        height: 35px;
        display: flex;
        background: var(--neutral-200);
        border: 1px solid var(--neutral-300);
        border-bottom: none !important;
        flex-direction: row;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
      }

      th:nth-child(1) {
        border-top-left-radius: 8px;
      }

      th:nth-child(3) {
        border-top-right-radius: 8px;
      }
    }

    tbody {
      tr {
        width: 100%;
        display: flex;
        
        .form-control {  
          border-radius: 0px !important;
        }
        .no-border {
          border-radius: 0px !important;
        }
      }

      tr:last-child {
        input:nth-child(1) {
          border-radius: 0px 0px 0px 8px !important;
        }

        input:nth-child(3) {
          border-radius: 0px 0px 8px 0px !important;
        }
      }
    }
  }


  h5 {
    color: var(--dark-blue);
    font-weight: 700;
    font-size: 18px;
  }

  .chart-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 29px;
    .add {
      fill: var(--blue-500);
      width: 24px;
      height: 24px;
    }
  }

  .trash-icon {
    cursor: pointer;
    display: flex;
    justify-content: end;
    flex-direction: row;
    width: 90px;
    height: 30px;
    stroke: var(--states-red-soft);
  }

  .hide {
    visibility: hidden;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
</style>